import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import Router from 'vue-router'
import store from './store'
import './plugins/element.js'
import $ from 'jquery'
import { Message } from "element-ui";
import '@/assets/icons'
import dayjs from 'dayjs';

Vue.config.productionTip = false
//注：官方vue-router@3.0及以上新版本路由默认回调返回的都是promise，原先就版本的路由回调将废弃！！！！
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//封装全局Ajax公共函数
Vue.prototype.$ajax = function(url, method, data, async, success, error) {
  $.ajax({
    url: url,
    type: method,
    dataType: 'json',
    contentType: "application/json",
    traditional: true,
    xhrFields: {
      withCredentials: true
    },
    headers: {
      "token": sessionStorage.getItem("token")
    },
    async: async,
    data: JSON.stringify(data),
    success: function(resp) {
      if (resp.code == 0) {
        success(resp)
      } else {
        error()
        Message.error({
          message: resp.msg,
          duration: 1200
        });
      }
    },
    error: function(e) {
      if (e.status == undefined) {
        Message.error({
          message: "前端页面错误",
          duration: 1200
        });
      } else {
        let status = e.status
        if (status == 401) {
          router.push({
            name: 'Login'
          })
        } else {
          Message.error({
            message: e.responseText,
            duration: 1200
          });
        }
      }
    }
  })
}

//全局自定义指令
Vue.directive('perms',{
  inserted(el, binding, vnode, oldVnode) {
    let perms = sessionStorage.getItem("perms");
    if(perms == null || perms == '' || perms == undefined) {
      perms=[]
    }
    else if(perms.split(',').length>0) {
      perms = perms.split(',');
    }
    //perms = perms ? JSON.parse(perms) : [];
    let perm = perms.find(item => item===binding.value)
    if(!perm) {
		el.parentNode.removeChild(el);
    }
  }
});

// 防止el-button重复点击
Vue.directive('preventDbClick', {
	inserted(el, binding) {
		el.addEventListener('click', () => {
			if (!el.disabled) {
				el.disabled = true;
				el.style.cursur='not-allowed'
				setTimeout(() => {
					el.disabled = false
					el.style.cursor ='pointer'
				}, binding.value || 2000)
			}
		})
	}
});

Vue.prototype.isAuth = function(permission) {
  let perms = sessionStorage.getItem("perms")
  let flag = false
  for (let item of permission) {
    if(perms.includes(item)) {
      flag = true
      break;
    }
  }
  return flag;
}
//配置JS生成PDF的公共函数
import JsPDF from 'jspdf'
import html2Canvas from 'html2canvas'
Vue.prototype.downloadPDF = function() {
  var title = this.htmlTitle //PDF标题
  let targetDom = document.querySelector('#pdfDom')
  html2Canvas(targetDom, {
    allowTaint: true,
    taintTest: false,
    useCORS: true,
    //width:960,
    //height:5072,
	height: targetDom.scrollHeight,
	windowHeight: targetDom.scrollHeight + 90, //这里加90px是因为交付详情的drawer，top：30px；保存交付单按钮：height:60px
    dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
    scale: 4 //按比例增加分辨率
  }).then(function(canvas) {
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    let pageHeight = contentWidth / 592.28 * 841.89
    let leftHeight = contentHeight
    let position = 0
    let imgWidth = 595.28
    let imgHeight = (592.28 / contentWidth) * contentHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)
    let PDF = new JsPDF('', 'pt', 'a4')
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        position -= 841.89
        if (leftHeight > 0) {
          PDF.addPage()
        }
      }
    }
    PDF.save(title + '.pdf')
  })
}

Vue.filter('datetimeFormatter', function (datetime) {
  const dt = dayjs(datetime).format('YYYY-MM-DD HH:mm:ss');
  return dt;
})

Vue.filter('numberFormatter', function (val) {
	return val.toFixed(2);
})

Vue.filter('startCertCode', function(arr) {
	let arrs = []
	if(arr === null) {
		return '';
	}
	if(arr.length>1) {
		arrs = arr.split(",")
		return arrs[0];
	} else {
		return arr;
	}
	// return Math.min(...arrs)
})
Vue.filter('endCertCode', function(arr) {
	if(arr === null) {
		return '';
	}
	let arrs = []
	if(arr.length>1) {
		arrs = arr.split(",")
		return arrs[arrs.length - 1]
	} else {
		return arr;
	}
	// return Math.max(...arrs)
	
})






